import React, { useState } from "react"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactStyles from "./contact.module.css"
import CityMap from "../components/CityMap"
import Footer from "../components/Footer"
import Address from "../components/Address"

const Contact = () => {
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const failedStatus = -1
  const initStatus = 0
  const awatingStatus = 1
  const successStatus = 2

  const [responseStatus, setResponseStatus] = useState(initStatus)

  const resetResponseStatus = () => {
    setTimeout(() => {
      setName("")
      setSurname("")
      setEmail("")
      setPhone("")
      setMessage("")
      setResponseStatus(initStatus)
    }, 1000)
  }

  const handleSubmit = event => {
    setResponseStatus(awatingStatus)
    event.preventDefault()

    let data = new FormData()
    data.append("name", name)
    data.append("surname", surname)
    data.append("email", email)
    data.append("phone", phone)
    data.append("message", message)
    axios
      .post("https://manastir.co/contact.php", data)
      .then(function(response) {
        if (response.data.status === "success") {
          setResponseStatus(successStatus)
          resetResponseStatus()
        } else {
          setResponseStatus(failedStatus)
        }
      })
      .catch(function(error) {
        setResponseStatus(failedStatus)
      })
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <h1 className={"hidden"}>Contact</h1>
      <div className="row mt-90 ">
        <div className="col-12 col-sm mb-30">
          <div className={ContactStyles.contact_title}> Contact Us</div>
        </div>
      </div>
      <div className="row mb-60">
        <div className="col-4 col-sm">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6 col-sm mb-30">
                <input
                  className="input"
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required
                />
                <span className="underline"></span>
              </div>
              <div className="col-6 col-sm mb-30">
                <input
                  className="input"
                  name="surname"
                  placeholder="Surname"
                  type="text"
                  value={surname}
                  onChange={e => setSurname(e.target.value)}
                  required
                />
                <span className="underline"></span>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-6 col-sm mb-30">
                <input
                  className="input"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <span className="underline"></span>
              </div>
              <div className="col-6 col-sm mb-30">
                <input
                  className="input"
                  name="phone"
                  placeholder="Phone Number"
                  type="text"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  required
                />
                <span className="underline"></span>
              </div>
            </div>
            <div className="row mt-40">
              <div className="col-12 col-sm mb-30">
                <textarea
                  className="text-area"
                  name="message"
                  id=""
                  cols="30"
                  placeholder="Message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-12 col-sm">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  type="submit"
                  className={ContactStyles.button}
                  disabled={responseStatus !== initStatus}
                >
                  {responseStatus === initStatus && "SEND"}
                  {responseStatus === awatingStatus && "SENDING..."}
                  {responseStatus === successStatus && "SENT"}
                  {responseStatus === failedStatus && "FAILED"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-8 col-sm">
          <div className="mt-30"></div>
          <div></div>
          <Address />
          <CityMap />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={ContactStyles.embed_container}>
            <video playsInline autoPlay muted loop width="100%">
              <source
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/Manastir-contact.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      {/* <div className="row mt-40">
        <div className="col-12 col-sm">
          <img
            width="100%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-01.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-01.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-01@2x.jpg 2x"
          />
        </div>
      </div> */}
      <div className="row mt-40">
        <div className="col-5 col-sm">
          <img
            width="94.4%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-02.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-02.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-02@2x.jpg 2x"
          />
        </div>
        <div className="col-7 col-sm">
          <img
            align="right"
            width="100%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-03.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-03.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-03@2x.jpg 2x"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm">
          <img
            width="100%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-04.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-04.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-04@2x.jpg 2x"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm">
          <img
            width="100%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-05.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-05.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-05@2x.jpg 2x"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm">
          <img
            width="100%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-06.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-06.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-06@2x.jpg 2x"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm">
          <img
            width="100%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-07.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-07.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-07@2x.jpg 2x"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-sm">
          <img
            width="94.4%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-08.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-08.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-08@2x.jpg 2x"
          />
        </div>
        <div className="col-6 col-sm">
          <img
            align="right"
            width="94.5%"
            alt="Manastır part of moodley Design Group - moodley İstanbul"
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-09.jpg"
            srcSet="
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-09.jpg 1x, 
            https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/contact/MANASTIR-09@2x.jpg 2x"
          />
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Contact
