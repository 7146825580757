import React from "react"
import AddressStyle from "./address.module.css"
const Address = () => (
  <div className={`mt-40 hidden ${AddressStyle.text}`}>
    <span>
      Asmalı Mescit Mah. General Yazgan Sk. No:14/A 34430 Beyoğlu/İstanbul
    </span>
    <br />
    <span>+90 (212) 274 74 08 +90 (212) 274 48 83</span>
    <br />
    <span>merhaba@manastir.net</span>
    <br />
  </div>
)

export default Address
