import React from "react"
import CityMapStyles from "./cityMap.module.css"

const CityMap = () => (
  <a
    href="https://www.google.com/maps/place/Manast%C4%B1r/@41.0290911,28.9729933,19.14z/data=!4m5!3m4!1s0x0:0xb08e366773a1712!8m2!3d41.0291468!4d28.9732655"
    target="_blank"
  >
    <div className={CityMapStyles.wrapper}>
      <div className={CityMapStyles.overlay}>
        <div className={CityMapStyles.overlay_info}>
          <div className="work_title">Show location on the map</div>
        </div>
      </div>
    </div>
    <button className={`hidden col-sm mt-40 ${CityMapStyles.button}`}>
      SHOW LOCATION ON THE MAP
    </button>
  </a>
)

export default CityMap
